/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    Truck as TruckIcon,
    DollarSign as DollarSignIcon,
    TrendingUp as TrendingUpIcon,
    Users as EntitiesIcon,
    Settings as SettingsIcon,
} from 'react-feather';

import {
    Payment as PaymentIcon,
    Receipt as InvoiceIcon,
} from '@material-ui/icons';

import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const sections = [
    {
        items: [
            {
                title: 'Comercial',
                icon: TrendingUpIcon,
                href: '/app/quotations',
                items: [
                    {
                        title: 'Crear Cotización',
                        href: '/app/quotations/create',
                    },
                    {
                        title: 'Listado de cotizaciones',
                        href: '/app/quotations',
                    },
                    {
                        title: 'Plantillas',
                        href: '/app/quotation_templates',
                    },
                    {
                        title: 'Términos',
                        href: '/app/quotation_terms',
                    },
                    {
                        title: 'Condiciones',
                        href: '/app/quotation_conditions',
                    },
                ],
            },
            {
                title: 'Entidades',
                icon: EntitiesIcon,
                href: '/app/entities',
                items: [
                    {
                        title: 'Crear entidad',
                        href: '/app/entities/create',
                    },
                    {
                        title: 'Listado de entidades',
                        href: '/app/entities',
                    },
                ],
            },
            {
                title: 'Embarques',
                icon: TruckIcon,
                href: '/app/shipments',
                items: [
                    {
                        title: 'Consolidado',
                        href: '/app/master_shipments',
                    },
                    {
                        title: 'Lista de embarques',
                        href: '/app/shipments',
                    },
                ],
            },
            {
                title: 'Facturación',
                icon: InvoiceIcon,
                href: '/app/billing/',
                items: [
                    {
                        title: 'Facturas / Invoices',
                        href: '/app/billing/invoices',
                    },
                    {
                        title: 'Facturas Especiales',
                        href: '/app/billing/special_invoices',
                    },
                ],
            },
            {
                title: 'Cuentas por Pagar',
                icon: DollarSignIcon,
                href: '/app/cp/',
                items: [
                    {
                        title: 'Pago a Proveedores',
                        href: '/app/cp/requests',
                    },
                    {
                        title: 'Listado Cheques',
                        href: '/app/cp/checks',
                    },
                ],
            },

            {
                title: 'Cuentas por Cobrar',
                icon: PaymentIcon,
                href: '/app/cc',
                items: [
                    {
                        title: 'Estado de Cuenta',
                        href: '/app/cc/entities',
                    },
                    {
                        title: 'Listado de Recibos',
                        href: '/app/cc/payments',
                    },
                ],
            },
            {
                title: 'Mantenimiento',
                icon: SettingsIcon,
                href: '/app/settings',
                items: [
                    {
                        title: 'Servicios',
                        href: '/app/settings/business/services',
                    },
                    {
                        title: 'Ubicaciones',
                        href: '/app/settings/locations',
                    },
                    {
                        title: 'Series y correlativos',
                        href: '/app/settings/document_counter',
                    },
                ],
            },
        ],
    },
];

function renderNavItems({items, pathname, depth = 0}) {
    return (
        <List disablePadding>
            {items.reduce((acc, item) => reduceChildRoutes({acc, item, pathname, depth}), [])}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth}) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {path: item.href, exact: false});
        acc.push(
            <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
                {renderNavItems({depth: depth + 1, pathname, items: item.items})}
            </NavItem>,
        );
    } else {
        acc.push(
            <NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />,
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
}));

const NavBar = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();
    const {user} = useAuth();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column" >
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display="flex" justifyContent="center" >
                        <RouterLink to="/app/account">
                            <Avatar alt="User" className={classes.avatar} src={user.avatar} />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
                            {user.name}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                            <Link component={RouterLink} to="/pricing">
                                {user.tier}
                            </Link>
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {sections.map((section, index) => (
                        <List key={index}
                            subheader={(
                                <ListSubheader disableGutters disableSticky >
                                    {section.subheader}
                                </ListSubheader>)}
                        >
                            {renderNavItems({items: section.items, pathname: location.pathname})}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" classes={{paper: classes.mobileDrawer}} onClose={onMobileClose} open={openMobile} variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor="left" classes={{paper: classes.desktopDrawer}} open variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
