/* eslint-disable react/display-name */
import React, {
    Suspense,
    Fragment,
    lazy,
} from 'react';
import {
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes ?
                                        renderRoutes(route.routes) :
                                        <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView')),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView')),
    },
    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView')),
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            // Shipments
            {
                exact: true,
                path: '/app/shipments',
                component: lazy(() => import('src/views/shipment/ListView')),
            },
            {
                exact: true,
                path: '/app/shipments/:shipmentID',
                component: lazy(() => import('src/views/shipment/DetailsView')),
            },
            {
                exact: true,
                path: '/app/master_shipments',
                component: lazy(() => import('src/views/master_shipment/MasterShipmentList')),
            },
            {
                exact: true,
                path: '/app/master_shipments/:shipment_id',
                component: lazy(() => import('src/views/master_shipment/MasterShipmetDetails')),
            },

            // Invoices
            {
                exact: true,
                path: '/app/billing/invoices',
                component: lazy(() => import('src/views/invoice/InvoiceListView')),
            },
            {
                exact: true,
                path: '/app/billing/invoices/:invoice_id',
                component: lazy(() => import('src/views/invoice/InvoiceDetailsView')),
            },

            // Special invoices
            {
                exact: true,
                path: '/app/billing/special_invoices',
                component: lazy(() => import('src/views/special_invoices/SpecialInvoicesListView')),
            },
            {
                exact: true,
                path: '/app/billing/special_invoices/create',
                component: lazy(() => import('src/views/special_invoices/SpecialInvoicesCreateView')),
            },
            {
                exact: true,
                path: '/app/billing/special_invoices/:special_invoice_id',
                component: lazy(() => import('src/views/special_invoices/SpecialInvoicesDetailsView')),
            },

            // Payment request
            {
                exact: true,
                path: '/app/cp/requests',
                component: lazy(() => import('src/views/payment_orders/Requests/index')),
            },
            {
                exact: true,
                path: '/app/cp/requests/:payment_order_id',
                component: lazy(() => import('src/views/payment_orders/Details/index')),
            },

            // Payment documents
            {
                exact: true,
                path: '/app/accounting/payments/documents',
                component: lazy(() => import('src/views/payment_orders/Document/index')),
            },

            {
                exact: true,
                path: '/app/cc/entities',
                component: lazy(() => import('src/views/receivables/EntityList')),
            },
            {
                exact: true,
                path: '/app/cc/payments',
                component: lazy(() => import('src/views/customer_payments/PaymentsListView')),
            },
            {
                exact: true,
                path: '/app/cc/payments/:payment_id',
                component: lazy(() => import('src/views/customer_payments/PaymentDetails')),
            },
            {
                exact: true,
                path: '/app/cc/entities/:entity_id/account_statement',
                component: lazy(() => import('src/views/receivables/AccountStatementView')),
            },
            {
                exact: true,
                path: '/app/cp/checks',
                component: lazy(() => import('src/views/checks/CheckList')),
            },

            // Quotations
            {
                exact: true,
                path: '/app/quotations',
                component: lazy(() => import('src/views/quotations/QuoteListView')),
            },
            {
                exact: true,
                path: '/app/quotations/create',
                component: lazy(() => import('src/views/quotations/QuoteCreateView')),
            },
            {
                exact: true,
                path: '/app/quotations/:quotation_id',
                component: lazy(() => import('src/views/quotations/QuoteDetailsView')),
            },
            {
                exact: true,
                path: '/app/quotations/:quote_id',
                component: lazy(() => import('src/views/quotations/QuoteDetailsView')),
            },
            {
                exact: true,
                path: '/app/quotations/:quote_id/instruct',
                component: lazy(() => import('src/views/quotations/QuoteInstructView')),
            },
            {
                exact: true,
                path: '/app/quotation_templates',
                component: lazy(() => import(`src/views/quotation_templates/QuotationTemplatesListView`)),
            },
            {
                exact: true,
                path: '/app/quotation_templates/:template_id',
                component: lazy(() => import(`src/views/quotation_templates/QuotationTemplateDetailsView`)),
            },
            {
                exact: true,
                path: '/app/quotation_conditions',
                component: lazy(() => import(`src/views/conditions/ConditionsListView`)),
            },
            {
                exact: true,
                path: '/app/quotation_terms',
                component: lazy(() => import(`src/views/terms/TermListView`)),
            },


            // Entities
            //
            // Customers
            {
                exact: true,
                path: '/app/entities',
                component: lazy(() => import('src/views/entities/EntityListView')),
            },
            {
                exact: true,
                path: '/app/entities/create',
                component: lazy(() => import(`src/views/entities/EntitiesFormView`)),
            },
            {
                exact: true,
                path: '/app/entities/:entity_id',
                component: lazy(() => import('src/views/entities/EntityDetailView')),
            },

            // Settings
            //
            // Services
            {
                exact: true,
                path: '/app/settings/business/services',
                component: lazy(() => import('src/views/settings/SettingsServicesView')),
            },
            {
                exact: true,
                path: '/app/settings/locations',
                component: lazy(() => import('src/views/settings/LocationsView')),
            },
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView')),
            },
            {
                exact: true,
                path: '/app/management/invoices',
                component: lazy(() => import('src/views/invoice/InvoiceListView')),
            },
            {
                exact: true,
                path: '/app/management/invoices/:invoiceId',
                component: lazy(() => import('src/views/invoice/InvoiceDetailsView')),
            },
            {
                exact: true,
                path: '/app/settings/document_counter',
                component: lazy(() => import('src/views/maintenance/DocumentCounter')),
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: () => <Redirect to="/app/shipments" />,
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
];

export default routes;
