import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import mock from 'src/utils/mock';

const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '1 days';

const users = [
  {
    id: '10',
    name: 'Agar Cordova',
    email: 'agar.cordova@aselogyca.com',
    password: 'Filadelfia6351?siena',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '20',
    name: 'Alfonso Monroy',
    email: 'alfonso.monroy@aselogyca.com',
    password: 'seul8367%oliva',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '30',
    name: 'Anaytee Enriquez',
    email: 'anaytee.enriquez@aselogyca.com',
    password: 'Pekin5985<rosa',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '40',
    name: 'Andrea Correa',
    email: 'andrea.correa@aselogyca.com',
    password: 'Paris7498#azul',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '50',
    name: 'Brenda del Cid',
    email: 'brenda.delcid@aselogyca.com',
    password: 'Bredel2937.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '60',
    name: 'Brissel Marroquín',
    email: 'brissel.marroquin@aselogyca.com',
    password: 'BriMar5148.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '70',
    name: 'Carlos Jiménez',
    email: 'carlos.jimenez@aselogyca.com',
    password: 'Amsterdam8202(sesamo',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '80',
    name: 'Carmen Martinez',
    email: 'carmen.martinez@aselogyca.com',
    password: 'Moscu1192)lavanda',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '90',
    name: 'César Pacheco',
    email: 'cesar.pacheco@aselogyca.com',
    password: 'Estocolmo9408>zinc',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '100',
    name: 'Claudia Castillo',
    email: 'claudia.castillo@aselogyca.com',
    password: 'Varsovia6344(cafe',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '110',
    name: 'Claudia Monroy',
    email: 'claudia.monroy@aselogyca.com',
    password: 'Lisboa1294>negro',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '120',
    name: 'Douglas Herrera',
    email: 'douglas.herrera@aselogyca.com',
    password: 'DouHer7667.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '130',
    name: 'Edgar Giron',
    email: 'edgar.giron@aselogyca.com',
    password: 'Canton6708+amarillo',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '140',
    name: 'Ernesto Santi',
    email: 'ernesto.santi@aselogyca.com',
    password: 'Boston6122;naranja',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '150',
    name: 'Ervin Poton',
    email: 'ervin.poton@aselogyca.com',
    password: 'Houston7528%ocre',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '160',
    name: 'Estuardo Diaz',
    email: 'estuardo.diaz@aselogyca.com',
    password: 'Shanghai3818;malva',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '170',
    name: 'Eva Orellana',
    email: 'eva.orellana@aselogyca.com',
    password: 'EvaOre4953.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '180',
    name: 'Francisca Suy',
    email: 'francisca.suy@aselogyca.com',
    password: 'Tokio4794!verde',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '190',
    name: 'Francisco Sanchez',
    email: 'francisco.sanchez@aselogyca.com',
    password: 'Telaviv6537?marfil',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '200',
    name: 'Frender Almazan',
    email: 'frender.almazan@aselogyca.com',
    password: 'FreAlm4813.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '210',
    name: 'Genesis Gudiel',
    email: 'genesis.gudiel@aselogyca.com',
    password: 'Londres3251?jade',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '220',
    name: 'Hector Chet',
    email: 'hector.chet@aselogyca.com',
    password: 'Sapporo6228<blanco',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '230',
    name: 'Hector Suruy',
    email: 'hector.suruy@aselogyca.com',
    password: 'HecSur7086.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '240',
    name: 'Helen Godinez',
    email: 'helen.godinez@aselogyca.com',
    password: 'HelGod2027.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '250',
    name: 'Helmuth Morales',
    email: 'helmuth.morales@aselogyca.com',
    password: 'HelMor5444.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '260',
    name: 'Jazmin Herrera',
    email: 'jazmin.herrera@aselogyca.com',
    password: 'Calcuta5077%celeste',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '270',
    name: 'Jhonnattan Bonilla',
    email: 'jhonnattan.bonilla@aselogyca.com',
    password: 'Brisbane5456)plata',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '280',
    name: 'Josefina Barillas',
    email: 'josefina.barillas@aselogyca.com',
    password: 'Yeda3506#dorado',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '290',
    name: 'Juan Juárez',
    email: 'juan.juarez@aselogyca.com',
    password: 'JuaJua1303.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '300',
    name: 'Karen Chun',
    email: 'karen.chun@aselogyca.com',
    password: 'Oslo5030!gris',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '310',
    name: 'Katherine Galindo',
    email: 'katherine.galindo@aselogyca.com',
    password: 'Incheon5210+marron',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '320',
    name: 'Lester Ortiz',
    email: 'lester.ortiz@aselogyca.com',
    password: 'Gante6532;ciruela',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '330',
    name: 'Lina Vinchira',
    email: 'lina.vinchira@aselogyca.com',
    password: 'Amberes5695;oro',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '340',
    name: 'Lisbeth Rodriguez',
    email: 'lisbeth.rodriguez@aselogyca.com',
    password: 'Berlin7754(lila',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '350',
    name: 'Luis Tahuite',
    email: 'luis.tahuite@aselogyca.com',
    password: 'LuiTah5309.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '360',
    name: 'Luis Villegas',
    email: 'luis.villegas@aselogyca.com',
    password: 'Osaka3665+rojo',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '370',
    name: 'Luisa Rodriguez',
    email: 'luisa.rodriguez@aselogyca.com',
    password: 'Toronto3752#ambar',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '380',
    name: 'Mariana Morán',
    email: 'mariana.moran@aselogyca.com',
    password: 'MarMor1361.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '390',
    name: 'Marlon Mena',
    email: 'marlon.mena@aselogyca.com',
    password: 'Orlando9376#beige',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '400',
    name: 'Michelle Pensabene',
    email: 'michelle.pensabene@aselogyca.com',
    password: 'Portland8095=turqui',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '410',
    name: 'Mildred Palacios',
    email: 'mildred.palacios@aselogyca.com',
    password: 'MilPal5634.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '420',
    name: 'Mireyli Rivera',
    email: 'mireyli.rivera@aselogyca.com',
    password: 'Chicago3483=zafiro',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '430',
    name: 'Nancy Itzep',
    email: 'nancy.itzep@aselogyca.com',
    password: 'NanItz1748.#',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '440',
    name: 'Osmarita Turcios',
    email: 'osmarita.turcios@aselogyca.com',
    password: 'Dallas5952>cian',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '450',
    name: 'Pablo Ortiz',
    email: 'pablo.ortiz@aselogyca.com',
    password: 'Sendai5439<melon',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '460',
    name: 'Pablo Flores',
    email: 'pablo.flores@aselogyca.com',
    password: 'Toulouse4208?lima',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '470',
    name: 'Percy Garcia',
    email: 'percy.garcia@aselogyca.com',
    password: 'Shenzhen6540=menta',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '480',
    name: 'Rafael Melgar',
    email: 'alejandro.melgar@aselogyca.com',
    password: 'Estambul5633)nieve',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '490',
    name: 'Recepción',
    email: 'recepcion@aselogyca.com',
    password: 'Chonging9329<lino',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '500',
    name: 'Roxana Pirir',
    email: 'roxana.pirir@aselogyca.com',
    password: 'Jinan2819>amaranto',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '510',
    name: 'Sindy Argueta',
    email: 'sindy.argueta@aselogyca.com',
    password: 'Hamburgo4424!violeta',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  },
  {
    id: '520',
    name: 'Wendy Lopez',
    email: 'wendy.lopez@aselogyca.com',
    password: 'Riverside8509)xanadu',
    tier: 'Aselogyca',
    avatar: '/static/images/avatars/avatar_13.png',
    canHire: false,
    country: 'USA',
    isPublic: true,
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York'
  }
];

mock.onPost('/api/account/login').reply(async (config) => {
  try {
    const { email, password } = JSON.parse(config.data);
    const user = users.find((_user) => _user.email === email);

    if (!user) {
      return [400, { message: 'Usuario o contraseña inválidos' }];
    }

    if (user.password !== password) {
      return [400, { message: 'Invalid password' }];
    }

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [
      200,
      {
        accessToken,
        user: {
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          tier: user.tier
        }
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/account/register').reply(async (config) => {
  try {
    const { email, name, password } = JSON.parse(config.data);
    let user = users.find((_user) => _user.email === email);

    if (user) {
      return [400, { message: 'User already exists' }];
    }

    user = {
      id: uuidv4(),
      avatar: null,
      canHire: false,
      country: null,
      email,
      isPublic: true,
      name,
      password,
      phone: null,
      role: 'admin',
      state: null,
      tier: 'Standard'
    };

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [
      200,
      {
        accessToken,
        user: {
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          tier: user.tier
        }
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/account/me').reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const { userId } = jwt.verify(accessToken, JWT_SECRET);
    const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [
      200,
      {
        user: {
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          tier: user.tier
        }
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Premium',
    price: 29,
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});
