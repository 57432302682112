import React from 'react';

import {AuthProvider} from 'src/contexts/JWTAuthContext';
import {createBrowserHistory} from 'history';
import {createTheme} from 'src/theme';
import {Router} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@material-ui/core';
import {QueryClient, QueryClientProvider} from 'react-query';

import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';

import routes, {renderRoutes} from 'src/routes';

const history = createBrowserHistory();
const queryClient = new QueryClient();

const App = () => {
    const {settings} = useSettings();

    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider dense maxSnack={3}>
                    <Router history={history}>
                        <AuthProvider>
                            <GlobalStyles />
                            <ScrollReset />

                            {renderRoutes(routes)}
                        </AuthProvider>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
