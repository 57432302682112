import 'nprogress/nprogress.css';
import 'src/__mocks__';
import React from 'react';
import ReactDOM from 'react-dom';
import {enableES5} from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import {SettingsProvider} from 'src/contexts/SettingsContext';
import App from 'src/App';

enableES5();

ReactDOM.render(
    <SettingsProvider>
        <App />
    </SettingsProvider>,
    document.getElementById('root'),
);

serviceWorker.register();
